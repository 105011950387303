<template>
  <dialogForm
    :title="dialogOption.title"
    :btnText="dialogOption.text"
    :btnType="dialogOption.type"
    width="500"
    customClass="import-product-template"
    v-model="form"
    :option="openOption"
    @before-open="beforeOpen"
    @closed="form = {}"
    @submit="onsubmit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #startTime>
      <el-date-picker :disabled="disabled" v-model="form.startTime" type="datetime" placeholder="请选择开始时间">
      </el-date-picker>
    </template>
    <template #endTime>
      <el-date-picker v-model="form.endTime" type="datetime" placeholder="请选择结束时间"> </el-date-picker>
    </template>
  </dialogForm>
</template>

<script>
import { openOption } from '../option.js'
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
import { parseCharacterTime } from '@/utils'
import { TIME_LONG, TIME_LIMITED, STATUS_OPEN } from '@/utils/constant'

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: 'open'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      openOption,
      form: {
        durationType: TIME_LONG,
        startTime: '',
        endTime: ''
      },
      disabled: false
    }
  },
  computed: {
    dialogOption({ type }) {
      return {
        open: {
          title: '设置',
          text: '开启',
          type: 'text'
        },

        sit: {
          title: '设置',
          text: '设置显示时间',
          type: 'text'
        }
      }[type]
    },
    // 是否是开启
    isOpen({ type }) {
      return type == 'open' ? true : false
    },
    fun({ isOpen }) {
      return isOpen ? recommendTopicsSitApi.enable : recommendTopicsSitApi.updateTime
    }
  },
  methods: {
    beforeOpen() {
      const { name, durationType, startTime, endTime } = this.data

      // 判断 设置时间且是限时显示且打开的时间已经过了上次设置的开始时间，把选择开始时间禁用掉
      if (this.type == 'sit' && durationType == TIME_LIMITED && new Date().getTime() > new Date(startTime).getTime())
        this.disabled = true

      // 当前时间
      let times = parseCharacterTime(new Date())

      // 结束时间当前时间加一天
      let timestamp = Date.now()
      timestamp += 86400000
      let tomorrow = parseCharacterTime(new Date(timestamp))
      const open = {
          startTime: times,
          endTime: tomorrow,
          name,
          durationType: TIME_LONG
        }
      const mapList = {
        sit: {
          [TIME_LONG]: {
            name,
            durationType,
            startTime: times,
            endTime: tomorrow
          },
          [TIME_LIMITED]: {
            startTime,
            endTime,
            name,
            durationType
          }
        }
      }
      this.form = this.type == 'open' ? open : mapList[this.type][durationType]

      return true
    },
    async onsubmit(form, done) {
      let { durationType, startTime, endTime } = form
      startTime = parseCharacterTime(startTime)
      endTime = parseCharacterTime(endTime)
      const { id } = this.data
      const mapList = {
        open: {
          [TIME_LONG]: {
            id,
            durationType,
            isUserEnable: STATUS_OPEN
          },
          [TIME_LIMITED]: {
            id,
            durationType,
            startTime,
            endTime,
            isUserEnable: STATUS_OPEN
          }
        },
        sit: {
          [TIME_LONG]: {
            id,
            durationType
          },
          [TIME_LIMITED]: {
            id,
            durationType,
            startTime,
            endTime
          }
        }
      }
      if (durationType == TIME_LIMITED) {
        if (!this.validateTime(form)) return done(false)
      }

      const parms = mapList[this.type][durationType]
      await done(this.fun({ ...parms }))
    },

    // 验证结束时间大于开始时间
    validateTime({ startTime, endTime }) {
      const time1 = new Date(startTime).getTime()
      const time2 = new Date(endTime).getTime()
      const currentTime = new Date().getTime()
      if (time2 <= time1) {
        this.$message.error('结束时间必须大于开始时间，请重新设置')
        return false
      }
      if (time2 < currentTime) {
        this.$message.error('结束时间必须大于当前时间，请重新设置')
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped></style>
