<template>
  <div class="app-container">
    <baseTable :option="option" :api="getList" :resetMergeData="resetMergeData">
      <template #menuLeft>
        <!-- 新建专题 -->
        <createOrEditTopic v-if="childPermission.add" type="add" @success="init"/>
      </template>
      <template #menu="{ row }">
        <!-- 编辑名称 -->
        <createOrEditTopic  v-if="childPermission.edit" :data="row" type="edit" @success="init"/>
        <el-button v-if="childPermission.editPro" type="text" @click="editPro(row)">编辑产品</el-button>
        <!-- 开启 -->
        <openOrSitTime class="timeText" v-if="childPermission.open && row.isUserEnable == STATUS_CLOSED" :data="row" type="open" @success="init"/>
        <!-- 设置显示时间 -->
        <openOrSitTime class="timeText" v-if="childPermission.sitTime && (row.isUserEnable == STATUS_OPEN||row.isUserEnable == STATUS_NO_OPEN_TIME)" :data="row" type="sit" @success="init"/>
        <!-- 关闭 -->
        <delOrClosedBtn v-if="childPermission.closed && (row.isUserEnable == STATUS_OPEN || row.isUserEnable == STATUS_NO_OPEN_TIME)" type="closed" :data="row" :init="init"/>
        <!-- 删除 -->
        <delOrClosedBtn v-if="childPermission.del" type="del" :data="row" :init="init"/>
      </template>
    </baseTable>
  </div>
</template>

<script>
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
import { option } from './option'
import createOrEditTopic from './module/createOrEditTopic'
import delOrClosedBtn from './module/delOrClosedBtn'
import openOrSitTime from './module/openOrSitTime'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import { checkPermission } from '@/utils'
import { STATUS_OPEN, STATUS_CLOSED,STATUS_NO_OPEN_TIME } from '@/utils/constant'
export const TOPIC_PAGE = 0 //专题商品页
export default {
  components: { createOrEditTopic, delOrClosedBtn, openOrSitTime },
  mixins: [baseTable],
  data() {
    this.getList = recommendTopicsSitApi.list
    return {
      option,
      STATUS_OPEN,
      STATUS_CLOSED,
      STATUS_NO_OPEN_TIME,
      childPermission: {
          add: checkPermission('externaladmin:market:recommendTopicsSit:add'),
          edit: checkPermission('externaladmin:market:recommendTopicsSit:edit'),
          del: checkPermission('externaladmin:market:recommendTopicsSit:del'),
          open: checkPermission('externaladmin:market:recommendTopicsSit:open'),
          closed: checkPermission('externaladmin:market:recommendTopicsSit:closed'),
          sitTime: checkPermission('externaladmin:market:recommendTopicsSit:sitTime'),
          editPro: checkPermission('externaladmin:market:recommendTopicsSit:editPro')
        },
        resetMergeData:{
          orderItems: [
          { asc: true, column: 'sort_number' },
          { asc: false, column: 'id' }
        ]
        }
    }
  },
  computed: {},
  methods: {
    editPro(data){
      const { name,id } = data
      this.$router.push({
          path: '/marketingManagement/addProduct',
          query: {
            name: name,
            id: id,
            value: TOPIC_PAGE
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .crud-container .crud-header-before {
    margin-bottom: 0;
  }
  .timeText  .el-button--text{
    color: green !important;
  }

}
</style>
