import { apiFactory } from '@/utils/constant'

const recommendTopicsSitApi = {
  list: '/marketingService/marketingCategory/list', //专题列表
  create: '/marketingService/marketingCategory/create', //专题创建
  updateName: '/marketingService/marketingCategory/updateName', //专题更新(名称)
  updateTime: '/marketingService/marketingCategory/updateTime', //专题更新(显示时间)
  delete: '/marketingService/marketingCategory/delete', //专题删除
  enable: '/marketingService/marketingCategory/enable', //专题开启/关闭
  batchSet: '/marketingService/marketingProductPrototypeRelation/batchSet', //专题原型-批量保存(增加/移除)
}

for (const key in recommendTopicsSitApi) {
  recommendTopicsSitApi[key] = apiFactory(recommendTopicsSitApi[key])
}

export default recommendTopicsSitApi