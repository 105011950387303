<template>
  <dialogForm
    :title="dialogOption.title"
    :btnText="dialogOption.text"
    :btnType="dialogOption.type"
    width="500"
    customClass="import-product-template"
    v-model="form"
    :option="addOption"
    @before-open="beforeOpen"
    @closed="form = {}"
    @submit="onsubmit"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </dialogForm>
</template>

<script>
import { addOption } from '../option.js'
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
export const ALL_PAGE = 1 //全部商品页

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: 'add'
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      addOption,
      form: {}
    }
  },
  computed: {
    dialogOption({ type }) {
      return {
        add: {
          title: '新建专题',
          text: '新建专题',
          type: 'primary'
        },

        edit: {
          title: '编辑专题',
          text: '编辑名称',
          type: 'text'
        }
      }[type]
    },
    // 是否是新建专题
    isAdd({ type }) {
      return type == 'add' ? true : false
    },
    fun({ isAdd }) {
      return isAdd ? recommendTopicsSitApi.create : recommendTopicsSitApi.updateName
    }
  },
  methods: {
    beforeOpen() {
      if (this.isAdd) return true
      const { name, sortNumber } = this.data
      this.form = {
        name,
        sortNumber
      }
      return true
    },
    async onsubmit(form, done) {
      const res = await done(this.fun({ ...form, id: this.isAdd ? undefined : this.data.id }))
      if (res && this.isAdd) {
        this.$router.push({
          path: '/marketingManagement/addProduct',
          query: {
            name: form.name,
            id: res.detail,
            value: ALL_PAGE
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
