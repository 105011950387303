<template>
  <div class="delBtn">
    <el-popover placement="top" width="160" v-model="visible">
      <p>是否{{btnTitle.content}}？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="onsubmit" :loading="loading">确定</el-button>
      </div>
      <ColorTextBtn slot="reference" :type="btnTitle.type" size="small">{{ btnTitle.text }}</ColorTextBtn>
    </el-popover>
  </div>
</template>

<script>
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
import { TIME_LONG, STATUS_CLOSED } from '@/utils/constant'
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: 'closed'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    init: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    }
  },
  computed: {
    btnTitle({ type }) {
      return {
        closed: {
          content: '关闭推荐',
          text: '关闭',
          type: 'danger'
        },
        
        del: {
          content: '删除专栏',
          text: '删除',
          type: 'info'
        }
      }[type]
    },
    fun({ type,data }) {
      const id = data.id
      const mapList = {
        closed:{
          fun:recommendTopicsSitApi.enable,
          parms:{
            isUserEnable:STATUS_CLOSED,
            id,
            durationType:TIME_LONG
          }
          
        },
        
        del:{
          fun:recommendTopicsSitApi.delete,
          parms:{
            idList:[id]
          }
        }
      }
      return mapList[type]
    }
  },
  methods: {
    async onsubmit() {
      const {fun,parms } = this.fun
      this.loading = true
      const res= await fun({ ...parms })
      if (!res) {
        this.$message.error('操作失败')
      this.visible = false

      } else {
        this.$message.success('操作成功')
      }
      this.init()
      this.loading = false
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.delBtn {
  display: inline-block;
}
</style>
