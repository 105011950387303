import { requireFun } from '@/utils'
import { isIntegerAndZero, charLenLimitConstructor } from '@/utils/validate'
import store from '@/store'
import { TIME_LONG, TIME_LIMITED, STATUS_OPEN, STATUS_CLOSED, STATUS_NO_OPEN_TIME } from '@/utils/constant'

export const option = {
  column: [
    {
      label: '优先级',
      prop: 'sortNumber'
    },
    {
      label: '推荐专题名称',
      prop: 'name'
    },
    {
      label: '产品数量',
      prop: 'productQuantity'
    },
    {
      label: '推荐状态',
      prop: 'isUserEnable',
      formatter(row){
        const { isUserEnable } = row
        const map ={
          [STATUS_CLOSED]:'已关闭',
          [STATUS_OPEN]:`<span style="color:green;">已启用</span>`,
          [STATUS_NO_OPEN_TIME]:'未到开启时间',
        }

        return map[isUserEnable]
      }
    },
    {
      label: '显示时间',
      prop: 'startTime',
      formatter(row) {
        const { isUserEnable, durationType, startTime, endTime }=row
        const mapList = {
          [STATUS_CLOSED] : '-',
          [STATUS_OPEN]:{
            [TIME_LONG]: '长期',
            [TIME_LIMITED]: `<p>开始时间:${startTime}</p>
                <p>结束时间:${endTime}</p>`
          },
          [STATUS_NO_OPEN_TIME]:{
            [TIME_LONG]: '长期',
            [TIME_LIMITED]: `<p>开始时间:${startTime}</p>
                <p>结束时间:${endTime}</p>`
          }
        }
        return mapList[isUserEnable][durationType]
      }
    }
  ] 
}

export const addOption = {
  menuAlign: 'left',
  menu: true,
  addBtn: true,
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  labelPosition:'left',
  column: [
    {
      label: '专题名称',
      prop: 'name',
      rules: [
        {
          required: true,
          message: requireFun('专题名称')
        },
        {
          validator:charLenLimitConstructor(1,10)
        }
      ]
    },
    {
      label: '优先级',
      prop: 'sortNumber',
      rules: [
        {
          required: true,
          message: requireFun('排序')
        },
        {
          validator:isIntegerAndZero
        },
        {
          validator:charLenLimitConstructor(0,6)
        }
      ]
    }
  ]
}

export const openOption = {
  menuAlign: 'left',
  menu: true,
  addBtn: true,
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  labelPosition:'left',
  column: [
    {
      label: '专题名称',
      prop: 'name',
      disabled: true
    },
    {
      label: '推荐时间',
      prop: 'durationType',
      type: 'radio',
      rules: [
        {
          required: true,
          message: requireFun('推荐时间')
        }
      ],
      dicData: store.getters.durationType,
      control(value) {
        return {
          startTime: {
            hide: value === 0
          },
          endTime: {
            hide: value === 0
          }
        }
      }
    },
    {
      label: '开始时间',
      prop: 'startTime',
      rules: [
        {
          required: true,
          message: requireFun('开始时间')
        }
      ],
    },
    {
      label: '结束时间',
      prop:'endTime',
      rules: [
        {
          required: true,
          message: requireFun('结束时间')
        }
      ]
    }
  ]
}

